/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { useEffect, useState, useMemo } from "react";
import WelcomeScreen from "./WelcomeScreen";
import SignupAndSignInSection from "./SignupAndSignInSection";
import { Provider } from "react-redux";
import { store } from "../../newCheckout/redux/store";
import SuccessScreen from "./SuccessScreen";
import { getCookie } from "../../../lib/helpers";
import PropTypes from "prop-types";

import { handleGet } from "../../new-login-create-account/utilities";

const userToken = getCookie("userToken") || null;

export const ListContext = React.createContext(null);

const initialValues = {
  backgroundColor: "#c7bdb3",
  accentColor: "#8d7144",
  header: "<p>Become a Guest House Ambassador</p>",
  body: "<p>Share a unique referral offer on social or the web - earn $25 for e very referral that dines with us.</p>",
  button: "<p>Generate your link</p>",
  status: "active",
  referralAmount: "45",
  minimumSpend: "54",
  brandName: "Prequel",
  brandLogo:
    "https://res-5.cloudinary.com/equityeats-test/image/upload/c_fit,w_120/v1631398069/zeasyrh2xwowwa0yijnn.png",
  brandDarkLogo:
    "https://res-5.cloudinary.com/equityeats-test/image/upload/c_fit,w_120/v1631398062/k1gp95s8pemttbrsbnr9.png",
  ambassadorProgramLink: "http://ambassador.localhost:3000/prequel",
  brandHeroImageUrl:
    "https://res-5.cloudinary.com/equityeats-test/image/upload/v1626924485/jdewaiuow4cwjheoepcv.jpg",
  locationsCount: 260,
};

export default function AmbassadorWebDiscovery({
  locationsCount,
  cloudinaryPath,
  ambassadorProgram,
}) {
  const [nearbyPrograms, setNearbyPrograms] = useState(null);
  const [allActivePrograms, setAllActivePrograms] = useState(null);
  const [step, setStep] = React.useState(1);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [setError] = React.useState("");
  const [isLoggedIn, setIsLoggedIn] = React.useState(null);
  const [allOffersData, setAllOffersData] = useState([]);
  const [user, setUser] = React.useState({
    user: null,
    shouldShowToast: false,
  });
  const getUserData = async () => {
    const headers = {
      Authorization: "Bearer ".concat(userToken),
    };
    const result = await handleGet("/api/v5/current_user", headers, setError);
    if (result) {
      await setUser({ ...user, user: { ...result.user } });
    }
  };
  useEffect(() => {
    setIsLoggedIn(!!userToken);
    if (userToken) {
      async function fetchUser() {
        await getUserData();
      }
      fetchUser();
    }
  }, []);
  const contextValue = useMemo(
    () => ({
      userList,
      setUserList,
      currentPage,
      setCurrentPage,
      isLoggedIn,
      setIsLoggedIn,
      user,
      setStep,
      locationsCount,
      allOffersData,
      setAllOffersData,
    }),
    [
      userList,
      setUserList,
      currentPage,
      setCurrentPage,
      isLoggedIn,
      setIsLoggedIn,
      user,
      setStep,
      locationsCount,
      allOffersData,
      setAllOffersData,
    ],
  );
  return (
    <Provider store={store}>
      <ListContext.Provider value={contextValue}>
        <div className={`ambassador-web-discovery-parent`}>
          <div className={`${step > 1 ? "cover" : ""}`}>
            {(step === 1 || isLoggedIn) && (
              <WelcomeScreen
                setStep={setStep}
                user={user.user}
                loggedIn={isLoggedIn}
                step={step}
                setNearbyPrograms={setNearbyPrograms}
                nearbyPrograms={nearbyPrograms}
                allActivePrograms={allActivePrograms}
                setAllActivePrograms={setAllActivePrograms}
                locationsCount={locationsCount}
                cloudinaryPath={cloudinaryPath}
                ambassadorProgram={ambassadorProgram}
              />
            )}
            {step === 2 && (
              <SignupAndSignInSection
                initialValues={initialValues}
                setStep={setStep}
                setUser={setUser}
                step={step}
                setNearbyPrograms={setNearbyPrograms}
                nearbyPrograms={nearbyPrograms}
                allActivePrograms={allActivePrograms}
                setAllActivePrograms={setAllActivePrograms}
                cloudinaryPath={cloudinaryPath}
              />
            )}
            {step === 3 && (
              <SuccessScreen
                setStep={setStep}
                user={user.user}
                setNearbyPrograms={setNearbyPrograms}
                nearbyPrograms={nearbyPrograms}
                allActivePrograms={allActivePrograms}
                setAllActivePrograms={setAllActivePrograms}
                cloudinaryPath={cloudinaryPath}
                initialValues={initialValues}
              />
            )}
          </div>
        </div>
      </ListContext.Provider>
    </Provider>
  );
}

AmbassadorWebDiscovery.propTypes = {
  locationsCount: PropTypes.string,
  cloudinaryPath: PropTypes.string,
  ambassadorProgram: PropTypes.object,
};
