import { useState, useEffect } from "react";
const COOKIE_SESSION_EXPIRY = 365;
const DEVICE_ID = "device_id";
import useCookie from "@lib/use-cookie";

const useDeviceId = () => {
  const [deviceId, setDeviceId] = useState<string>("");
  const [, updateDeviceIdCookie, getCookie] = useCookie(DEVICE_ID);
  const finalCookie = getCookie();

  useEffect(() => {
    const getData = () => {
      const data = [
        navigator.userAgent,
        navigator.language,
        screen.colorDepth,
        screen.width,
        screen.height,
        screen.availHeight,
        new Date().getTime(),
        new Date().getTimezoneOffset(),
        window.localStorage ? Object.keys(localStorage).join("") : "",
        window.sessionStorage ? Object.keys(sessionStorage).join("") : "",
        Array.prototype.slice
          .call(navigator.plugins)
          .map((p: Plugin) => p.name)
          .join(","),
        Array.prototype.slice
          .call(navigator.mimeTypes)
          .map((m: MimeType) => m.type)
          .join(","),
      ];

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.textBaseline = "top";
        ctx.font = "16px Arial";
        ctx.fillStyle = "#f60";
        ctx.fillRect(125, 1, 62, 20);
        ctx.fillStyle = "#069";
        ctx.fillText("Browser Fingerprint", 2, 15);
        ctx.fillStyle = "rgba(102, 200, 0, 0.7)";
        ctx.fillText("Browser Fingerprint", 4, 17);
      }
      const canvasFingerprint = canvas.toDataURL();
      data.push(canvasFingerprint);

      const textEncoder = new TextEncoder();
      const encodedData = textEncoder.encode(data.join(""));
      return encodedData;
    };

    const calculateFingerprint = async () => {
      try {
        if (!getCookie()) {
          const encodedData = getData();
          const hashBuffer = await crypto.subtle.digest("SHA-256", encodedData);
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");
          updateDeviceIdCookie(hashHex, COOKIE_SESSION_EXPIRY);
          setDeviceId(hashHex);
        } else {
          setDeviceId(getCookie());
        }
      } catch (error) {
        console.error("Error computing the fingerprint:", error);
        setDeviceId("");
      }
    };

    calculateFingerprint();
  }, [finalCookie]);

  return deviceId;
};

export default useDeviceId;
