/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useState, memo, useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import InklndLogo from "../../../../assets/images/icons/inkind.png";
import { ListContext } from "./";
import PropTypes from "prop-types";

function LearnMore({
  currentRestaurantDetails,
  desktop,
  showButton = true,
  heading,
  updateCurrentOfferInfo,
}) {
  const { isLoggedIn, locationsCount, allOffersData } = useContext(ListContext);
  const [currentOfferInfo, setCurrentOfferInfo] = useState(null);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  useEffect(() => {
    if (allOffersData?.length && currentRestaurantDetails) {
      const offerInfo = allOffersData.find(
        (offer) =>
          offer.offer.brand.brand_slug === currentRestaurantDetails.brand_slug,
      );
      setCurrentOfferInfo(offerInfo);
      updateCurrentOfferInfo(offerInfo);
    }
    return () => {
      setCurrentOfferInfo(null);
    };
  }, [allOffersData, currentRestaurantDetails, updateCurrentOfferInfo]);
  return (
    <div className="learn-more-section" ref={myRef}>
      <button
        className={`learn-more ${desktop ? "desktop" : ""}`}
        type="button"
        onClick={executeScroll}
      >
        Learn more
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <div
        className={`offer-details show referral-link-details`}
        css={css`
          padding: 5px 20px 15px 20px !important;
        `}
      >
        <h2
          css={css`font-size: 13px!important;font-weight:700!important;margin-top:0;!important`}
        >
          {heading || "Referral Link Details"}
        </h2>
        <div className="offer-amount item">
          <h3 className="label">Offer Amount</h3>
          <span className="value">{`$${currentRestaurantDetails.referral_amount || ""} off $${
            currentRestaurantDetails.minimum_spend || ""
          }+`}</span>
        </div>
        <div className="referral-bonus item">
          <h3 className="label">Referral Bonus</h3>
          <span className="value">Up to 30% of guest’s bill</span>
        </div>
        {isLoggedIn && currentOfferInfo ? (
          <>
            <div className="completed-referrals item">
              <h3 className="label">Completed Referrals</h3>
              <span className="value">
                {currentOfferInfo?.completed_referrals || 0}
              </span>
            </div>
            <div className="your-balance item">
              <h3 className="label">Earned with this link</h3>
              <span className="value">{`$${currentOfferInfo?.earned || 0}`}</span>
            </div>
          </>
        ) : null}
        <button
          className="message item"
          onClick={() => {
            window.open("https://app.inkind.com/", "_blank", "noopener");
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent default for spacebar
              window.open("https://app.inkind.com/", "_blank", "noopener"); // Trigger click event on Enter or Space
            }
          }} // Handle keyboard events
        >
          <h3
            className="label"
            css={css`
              font-size: 16px;
            `}
          >{`Spend at ${locationsCount}+ restaurants on `}</h3>
          <span className="logo">
            <img src={InklndLogo} className="inkind-logo" alt="InKind Logo" />
          </span>
        </button>
      </div>
    </div>
  );
}

export default memo(LearnMore);

LearnMore.propTypes = {
  currentRestaurantDetails: PropTypes.object,
  desktop: PropTypes.bool,
  showButton: PropTypes.bool,
  heading: PropTypes.string,
};
