/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import CopyIcon from "../../../../assets/images/ambassador/CopyIcon.svg";
import ShareIcon from "../../../../assets/images/ambassador/share-icon.svg";
import LearnMore from "./LearnMore";
import Faqs, { faqsData } from "./Faqs";
import Footer from "./Footer";
import Header from "./Header";
import Error from "./Error";
import CarouselSectionMobile from "./CarouselSectionMobile";
import Tooltip from "./Tooltip";
import Loader from "../../shared/loader";

export default function GeneratedLink({
  user,
  setStep,
  brandSlug,
  initialValues,
}) {
  const [loading, setLoading] = useState(true);
  const [offerData, setOfferData] = useState(null);
  const [error, setError] = useState(false);
  const link = offerData?.referral_link;
  const [shareApiAvailable, setShareApiAvailable] = useState(null);
  const [showTooltips, setShowTooltip] = useState(false);

  const share = () => {
    console.log("Share Icon clicked");
    if (navigator.share) {
      try {
        navigator.share({
          title: "InKind",
          text: "Check out this amazing offer",
          url: offerData?.referral_link || "",
        });
      } catch (err) {
        console.log("Error sharing", err);
      }
    } else {
      console.log("Web Share API not supported");
    }
  };
  const copyLink = async () => {
    try {
      if (link) {
        // Copy from textRef if provided
        await navigator.clipboard.writeText(link);
      } else {
        // Otherwise, copy the entire document body
        await navigator.clipboard.writeText(document.body.textContent);
      } // Optional feedback to the user
      await setShowTooltip(true);
      await setTimeout(() => {
        setShowTooltip(false);
      }, 2500);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  useEffect(() => {
    async function fetchOfferData() {
      const headers = {
        Authorization: "Bearer ".concat(user.user.token),
        "content-type": "application/json",
      };
      await fetch("/api/v5/ambassadors", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          brand_slug: brandSlug,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            setError(true);
            return response.json();
          }
        })
        .then((response) => {
          setOfferData(response);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          setError(err);
        });

      setLoading(false);
    }
    fetchOfferData();
  }, []);
  useEffect(() => {
    if (navigator.share) {
      setShareApiAvailable(true);
    } else {
      setShareApiAvailable(false);
    }
  }, []);
  return (
    <>
      {error ? (
        <Error brandName={""} disabled={true} />
      ) : (
        <div className="start-sharing-generated-link mobile">
          {loading ? (
            <Loader
              className={css`
                margin: 0 auto;
                margin-top: 40px;
                margin-bottom: 20px;
              `}
            />
          ) : (
            <div className="container">
              <Header initialValues={initialValues} replaceLogo={false} />
              <main>
                <h1>Start sharing your link!</h1>
                <p>Here's other posts Ambassadors have made</p>
                <CarouselSectionMobile />

                <>
                  <div className="referral-section">
                    <p
                      css={css`
                        font-weight: 600 !important;
                        fdont-size: 18px !important;
                      `}
                    >
                      Share your referral link for
                      <span>
                        {` $${offerData?.offer?.amount || 0} off $${offerData?.offer?.minimum_spend_amount || 0}+`}
                      </span>
                    </p>
                    <div
                      className="referral-link"
                      css={css`
                        font-size: 18px;
                        border: 2px solid #000;
                      `}
                    >
                      <span
                        css={css`
                          font-size: 14px;
                        `}
                      >
                        {offerData?.referral_link || ""}
                      </span>
                      <div className="icons">
                        <span
                          onClick={copyLink}
                          className="copy-icon"
                          css={css`
                            width: 18px;
                            margin-right: 10px;
                            cursor: pointer;
                            position: relative;
                          `}
                        >
                          <img src={CopyIcon} alt={`${CopyIcon}-icon`} />
                          {showTooltips && <Tooltip text={"Copied"} />}
                        </span>
                        {shareApiAvailable && (
                          <span
                            onClick={share}
                            className="share-icon"
                            css={css`
                              width: 18px;
                              cursor: pointer;
                            `}
                          >
                            <img src={ShareIcon} alt={`${ShareIcon}-icon`} />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <LearnMore
                    offerData={offerData}
                    initialValues={initialValues}
                  />
                </>

                <Faqs
                  faqData={faqsData}
                  locationsCount={initialValues.locationsCount}
                />
                <Footer />
              </main>
            </div>
          )}
        </div>
      )}
    </>
  );
}
