/** @jsx jsx */
import { jsx } from "@emotion/react";
import WelcomeScreen from "./WelcomeScreen";
import { isMobileDevice } from "../2fa-user-flow";
import NewLoginCreateAccountUserFlow from "../2fa-user-flow/NewLoginCreateAccountUserFlow";
import PhoneMenu from "./PhoneMenu";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { debounce } from "../2fa-user-flow/CarouselSectionMobile";

export default function SignupAndSignInSection({
  setStep,
  setUser,
  initialValues,
  step,
  setNearbyPrograms,
  nearbyPrograms,
  allActivePrograms,
  setAllActivePrograms,
  cloudinaryPath,
}) {
  return (
    <div className="signup-and-signin">
      <div className="left-section">
        <WelcomeScreen
          setNearbyPrograms={setNearbyPrograms}
          nearbyPrograms={nearbyPrograms}
          allActivePrograms={allActivePrograms}
          setAllActivePrograms={setAllActivePrograms}
          cloudinaryPath={cloudinaryPath}
          locationsCount={initialValues.locationsCount}
        />
      </div>
      <div className="right-section">
        <NewLoginCreateAccountUserFlow
          country={"US"}
          setUser={setUser}
          siteArea={"ambassador_flow"}
          setStep={setStep}
          alwaysDesktop={true}
        />
        {isMobileDevice() ? <PhoneMenu step={step} setStep={setStep} /> : null}
      </div>
    </div>
  );
}

SignupAndSignInSection.propTypes = {
  setStep: PropTypes.func,
  setUser: PropTypes.func,
  initialValues: PropTypes.object,
  step: PropTypes.number,
  setNearbyPrograms: PropTypes.func,
  nearbyPrograms: PropTypes.array,
  allActivePrograms: PropTypes.array,
  setAllActivePrograms: PropTypes.func,
  cloudinaryPath: PropTypes.string,
};
