/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import ListRestaurant from "../../../../assets/images/ambassador/list-restaurant.png";
import Profile from "../../../../assets/images/ambassador/profile.png";

export function ListYourRestaurantButton() {
  const listYourRestaurants = () => {
    window.location.href = "https://inkindcapital.com/";
  };
  return (
    <button
      onClick={listYourRestaurants}
      className="list-your-restaurant"
      css={css`
        position: absolute;
        left: 30px;
        top: 40px;
      `}
    >
      <span
        css={css`
          margin-right: 10px;
        `}
      >
        <img src={ListRestaurant} alt="List-your-restaurant" />
      </span>
      {/*
       */}
      List your restaurant
    </button>
  );
}

export function GetStartedButton() {
  const getStarted = () => {
    console.log("get started clicked");
  };
  return (
    <button onClick={getStarted} className="get-started black-button">
      Get Started
    </button>
  );
}

export function LoginButton({ setStep }) {
  const login = () => {
    console.log("Login clicked");
    setStep(2);
  };
  return (
    <button
      onClick={login}
      className="profile"
      css={css`
        position: absolute;
        right: 30px;
        top: 40px;
      `}
    >
      <span
        css={css`
          margin-right: 10px;
        `}
      >
        <img src={Profile} alt="login" />
      </span>
      {/*
       */}
      Log In
    </button>
  );
}
