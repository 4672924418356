/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useEffect, useContext, useState, useRef } from "react";
import TopSection from "./TopSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Faqs from "../2fa-user-flow/Faqs";
import LearnMore from "./LearnMore";
import { calculateFontColorFor } from "../2fa-user-flow/index";
import { ListContext } from "./";
import CopyIcon from "../../../../assets/images/ambassador/CopyIcon.svg";
import Tooltip from "../2fa-user-flow/Tooltip";
import App from "../../map_v2/App";
import PropTypes from "prop-types";

export default function RestaurantDetails({
  setIsRestaurantDetailsOpen,
  currentRestaurantDetails,
  cloudinaryPath,
}) {
  const {
    isLoggedIn,
    user,
    setStep,
    setAllOffersData,
    allOffersData,
    locationsCount,
  } = useContext(ListContext);
  const [offerData, setOfferData] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const sidebarRef = useRef(null);
  const [offerText, setOfferText] = useState("Share your");

  const updateCurrentOfferInfo = (newOfferInfo) => {
    setOfferData(newOfferInfo);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setOfferText("Share your");
      } else {
        setOfferText("Generate link for a");
      }
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const scrollableElement = document.getElementById("rd-main-parent");
    // Scroll to the top-left corner
    setTimeout(() => {
      scrollableElement.scrollTo(0, 0);
    }, 1000);
  }, []);

  const brandLogo = currentRestaurantDetails?.brand_dark_logo;

  const generateLink = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isLoggedIn) {
      const currentBrandSlug = currentRestaurantDetails?.brand_slug;
      async function fetchOfferData() {
        const headers = {
          Authorization: "Bearer ".concat(user.user.token),
          "content-type": "application/json",
        };
        const arr =
          currentRestaurantDetails?.ambassador_program_link.split("/");
        await fetch("/api/v5/ambassadors", {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            brand_slug: arr[arr.length - 1],
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            setOfferData(response);
            setAllOffersData([...allOffersData, response]);
            updateCurrentOfferInfo(response);
          })
          .catch((err) => {
            console.log("err", err);
            setError(err);
          });
      }
      if (allOffersData?.length > 0) {
        const offerPresent = allOffersData.find(
          (offer) => offer.offer.brand.brand_slug === currentBrandSlug,
        );
        if (offerPresent) {
          setOfferData(offerPresent);
          updateCurrentOfferInfo(offerPresent);
        } else {
          fetchOfferData();
        }
        return;
      }
      fetchOfferData();
    } else {
      window.history.replaceState(
        null,
        "Discover Ambassador Programs | inKind",
        "/",
      );
      setStep(2);
    }
  };

  const closeRestaurantDetails = () => {
    window.history.replaceState(
      null,
      "Discover Ambassador Programs | inKind",
      "/",
    );
    setIsRestaurantDetailsOpen(false);
  };

  const handleClickOutside = (event) => {
    const isScrollbarClick =
      event.clientX >= document.documentElement.clientWidth ||
      event.clientY >= document.documentElement.clientHeight;

    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      !event.target.closest(".top-section") &&
      !isScrollbarClick
    ) {
      setIsSidebarOpen(false);
      closeRestaurantDetails();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const copyLink = async () => {
    try {
      if (offerData?.referral_link) {
        // Copy from textRef if provided
        await navigator.clipboard.writeText(offerData?.referral_link);
      } else {
        // Otherwise, copy the entire document body
        await navigator.clipboard.writeText(document.body.textContent);
      } // Optional feedback to the user
      await setShowTooltip(true);
      await setTimeout(() => {
        setShowTooltip(false);
      }, 2500);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="rd-main-parent" id="rd-main-parent" ref={sidebarRef}>
      <div className="restaurant-details-parent">
        <TopSection />
        <div className="back-button">
          <button
            css={css`
              background: transparent;
              border: none;
              padding: 0;
              margin: 0;
              cursor: pointer;
              position: absolute;
              left: 30px;
              top: 20px;
            `}
            className="back-button"
            onClick={() => closeRestaurantDetails()}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </div>
        <div className="restaurant-info">
          <div className="restaurant-image">
            <img
              className="restaurant-hero"
              src={currentRestaurantDetails.brand_hero_sidebar_image_url}
              alt="Restaurant hero"
            />
            {brandLogo && (
              <img
                src={brandLogo}
                className="restaurant-logo"
                alt="Restaurant logo"
              />
            )}
          </div>
          <div className="restaurant-details">
            <h2>{currentRestaurantDetails?.brand_name || ""}</h2>
            <p>
              {currentRestaurantDetails?.brand_tags?.length
                ? currentRestaurantDetails?.brand_tags.map((tag, idx) =>
                    idx === 0 ? tag : ` · ${tag}`,
                  )
                : ""}
            </p>
            <p
              css={css`
                color: #808080;
              `}
            >
              {`${currentRestaurantDetails?.location_address}, ${currentRestaurantDetails?.location_city}, ${currentRestaurantDetails?.location_location_state} ${currentRestaurantDetails?.location_zip_code}`}
            </p>
            <p
              css={css`
                color: #808080;
                margin-bottom: 20px !important;
              `}
            >
              {`${currentRestaurantDetails?.distance} miles away`}
            </p>
            <p
              css={css`
                margin-bottom: 20px !important;
              `}
            >
              {currentRestaurantDetails?.brand_summary}
            </p>
            {currentRestaurantDetails?.brand_gallery &&
            currentRestaurantDetails?.brand_gallery?.images?.length ? (
              <a
                role="button"
                aria-disabled="true"
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigation
                  // Your click handler logic here
                }}
                href="#"
              >
                Photos
              </a>
            ) : null}
          </div>
          <div
            className="photo-gallery"
            css={css`
              display: flex;
            `}
          >
            {currentRestaurantDetails?.brand_gallery &&
            currentRestaurantDetails?.brand_gallery?.images?.length
              ? currentRestaurantDetails?.brand_gallery?.images.map(
                  (image, index) => {
                    return (
                      <div className="image-item" key={index + 1}>
                        <img
                          src={cloudinaryPath + image}
                          alt={`restaurant-${index + 1}`}
                        />
                      </div>
                    );
                  },
                )
              : null}
          </div>
        </div>
        <LearnMore
          currentRestaurantDetails={currentRestaurantDetails}
          showButton={false}
          heading="Offer details"
          updateCurrentOfferInfo={updateCurrentOfferInfo}
        />
        <App
          tiers={[]}
          cloudinaryPath={""}
          widget={true}
          enterprise={"inkind"}
          totalLocationCount={0}
          brandSlug={currentRestaurantDetails?.brand_slug}
          showSearchBox={false}
          showSidebar={false}
          showZoombox={false}
          isHamburgerRequired={false}
          showPassPopup={false}
        />
      </div>
      <Faqs faqData={faqsData} title={"FAQs"} locationsCount={locationsCount} />
      <section className="offer-section">
        <div className="offer-text">
          {offerText}{" "}
          <span>{`$${currentRestaurantDetails?.referral_amount} off $${currentRestaurantDetails?.minimum_spend} Offer`}</span>
        </div>
        <form onSubmit={generateLink}>
          <button
            type="submit"
            className={
              isLoggedIn && offerData?.referral_link
                ? "link-present gen-link-button"
                : "generate-button"
            }
          >
            <span className="generate-button-text">
              {offerData?.referral_link ? offerData?.referral_link : "Generate"}
            </span>
            {isLoggedIn && offerData?.referral_link ? (
              <span
                role="button"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    copyLink();
                  }
                }}
                onClick={copyLink}
                className="copy-icon"
              >
                <img src={CopyIcon} alt={`${CopyIcon}-icon`} />
                {showTooltip && <Tooltip text={"Copied"} />}
              </span>
            ) : null}
          </button>
        </form>
      </section>
    </div>
  );
}

export const faqsData = [
  {
    question: "How do guests use my ambassador referral link?",
    answer:
      "Potential guests can claim your offer and will be prompted to download inKind to pay their bill in-store using the inKind app. Payment through inKind is faster than paying by card.",
    open: false,
  },
  {
    question: "What is inKind?",
    answer:
      "inKind partners with the world’s best restaurants and operators to bring in amazing guests and reward them for their support. The inKind App is the best way for guests to unlock rewards and pay their bill at thousands of restaurants.",
    open: false,
  },
  {
    question: "Where should I share my ambassador link?",
    answer:
      "Share your ambassador link wherever potential guests might benefit from seeing it! TikTok, Facebook and instagram bio’s are a great place to start!",
    open: false,
  },
  {
    question: "Where can I spend the dining credit I earn?",
    answer:
      "Dining credit you earn through successfully getting a guest to visit a restaurant can be spent at any of the 1,750+ restaurants on the inKind app (Download it here). View a full list of participating restaurants here.",
    open: false,
  },
  {
    question: "Have more questions?",
    answer: "Feel free to contact us at support@inkind.com",
    open: false,
  },
];

RestaurantDetails.propTypes = {
  setIsRestaurantDetailsOpen: PropTypes.func,
  currentRestaurantDetails: PropTypes.object,
  cloudinaryPath: PropTypes.string,
};
